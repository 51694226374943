import React from "react";
import { Switch, Route } from "react-router-dom";
import "./App.css";
import Helmet from "react-helmet";
import { analytics } from "./pages/firebase";
import { lazy, Suspense } from "react";
import Header from "./pages/Header";
import Footer from "./pages/Footer";
// import { AuthProvider } from "./contexts/authContext";

const Home = lazy(() => import("./pages/Home"));
// const Performance = lazy(() => import("./pages/Performance"));
const About = lazy(() => import("./pages/About"));
const Contact = lazy(() => import("./pages/Contact"));
// const Lagniappe = lazy(() => import("./pages/Lagniappe"));
// const MidiInstrument = lazy(() => import("./pages/MidiInstrument"));
// const Podcast = lazy(() => import("./pages/Podcast"));
// const SignUp = lazy(() => import("./pages/SignUp"));
const TasteMoreInfo = lazy(() => import("./pages/TasteMoreInfo"));
const MissionToMarsMoreInfo = lazy(() =>
  import("./pages/missionToMarsMoreInfo")
);
const LaserFocusedMoreInfo = lazy(() => import("./pages/LaserFocusedMoreInfo"));
const TastePay = lazy(() => import("./pages/TastePay"));
const Login = lazy(() => import("./components/Login"));
const Login2 = lazy(() => import("./components/Login2"));
const SignOut = lazy(() => import("./components/SignOut"));
const Register = lazy(() => import("./components/Register"));
const Register2 = lazy(() => import("./components/Register2"));
// const LinkTree = lazy(() => import("./pages/LinkTree"));

function App() {
  return (
    <>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Masson LeMieux Official Website</title>
          <meta
            name="descriptioin"
            content="This is the official website for musician Masson LeMieux"
          />
        </Helmet>
        <div>
          <Header />
          <div className="app">
            <Switch>
              <Suspense fallback={<div className="fallback">Loading</div>}>
                {/* <Route
                  exact
                  path="/midiinstrument"
                  component={MidiInstrument}
                /> */}
                {/* <Route exact path="/podcast" component={Podcast} /> */}
                <Route exact path="/about" component={About} />
                {/* <Route exact path="/performance" component={Performance} /> */}
                {/* <Route exact path="/lagniappe" component={Lagniappe} /> */}
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/signin" component={Login2} />
                <Route exact path="/signout" component={SignOut} />
                <Route exact path="/register" component={Register2} />
                <Route exact path="/taste" component={TasteMoreInfo} />
                <Route exact path="/taste-sign-up" component={TastePay} />
                <Route
                  exact
                  path="/laserfocused"
                  component={LaserFocusedMoreInfo}
                />
                <Route
                  exact
                  path="/missiontomars"
                  component={MissionToMarsMoreInfo}
                />
                <Route
                  exact
                  path="/links"
                  component={() => {
                    window.location = "https://lemieuxstudios.com/links";
                    return null;
                  }}
                />
                <Route exact path="/" component={Home} />
              </Suspense>
            </Switch>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;
